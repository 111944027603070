import {LanguageCodes} from "./AppConsts";

/**
 * Official list of supported languages. This will be shown in the
 * pulldown menu
 */
export const SupportedLanguageList = [
  // default language
  {
    code: LanguageCodes.ENGLISH,
    name: "English",
    nativeName: "English",
    interestCode: "en",
  },
  {
    code: LanguageCodes.PORTUGUESE_BRAZIL,
    name: "Portuguese (Brazil)",
    nativeName: "Português (Brasil)",
    interestCode: "pt",
  },
  {
    code: LanguageCodes.SPANISH,
    name: "Spanish",
    nativeName: "Español",
    interestCode: "es",
  },
  {
    code: LanguageCodes.GERMAN,
    name: "German",
    nativeName: "Deutsch",
    interestCode: "de",
  },
  {
    code: LanguageCodes.HINDI,
    name: "Hindi",
    nativeName: "हिंदी",
    interestCode: "hi",
  },
  {
    code: LanguageCodes.JAPANESE,
    name: "Japanese",
    nativeName: "日本語",
    interestCode: "ja",
  },
  {
    code: LanguageCodes.CHINESE_SIMPLIFIED,
    name: "Chinese (Simplified)",
    nativeName: "中文（简体）",
    interestCode: "zh",
  },
  {
    code: LanguageCodes.CHINESE_TRADITIONAL,
    name: "Chinese (Traditional)",
    nativeName: "中文（繁體）",
    interestCode: "tw",
  },
];
